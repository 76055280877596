import axios from '../utils/axiosConfig';

export const fetchStrategyReturns = async (startDate, endDate) => {
  try {
    const response = await axios.get('/portfolio_returns/', {
      params: { start_date: startDate, end_date: endDate }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching strategy returns:', error);
    throw error;
  }
};

export const fetchPortfolioDrawdown = async (startDate, endDate) => {
  try {
    const response = await axios.get('/portfolio_drawdown/', {
      params: { start_date: startDate, end_date: endDate }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching portfolio drawdown:', error);
    throw error;
  }
};

export const fetchSpyReturns = async (startDate, endDate) => {
  try {
    const response = await axios.get('/returns/SPY', {
      params: { start_date: startDate, end_date: endDate }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching SPY returns:', error);
    throw error;
  }
};

export const fetchRollingSharpe = async (startDate, endDate) => {
  try {
    const response = await axios.get('/rolling_sharpe/', {
      params: { start_date: startDate, end_date: endDate }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching rolling Sharpe ratio:', error);
    throw error;
  }
};

export const fetchSpyDrawdown = async (startDate, endDate) => {
  try {
    const response = await axios.get('/spy_drawdown/', {
      params: { start_date: startDate, end_date: endDate }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching SPY drawdown:', error);
    throw error;
  }
};
