// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.returns-heatmap {
  margin-top: 20px;
}

.returns-heatmap .table-container {
  max-width: 800px;
  margin: 0 auto;
  overflow-x: auto;
}

.returns-heatmap table {
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}

.returns-heatmap th,
.returns-heatmap td {
  border: 1px solid #ddd;
  padding: 4px;
  text-align: center;
}

.returns-heatmap th {
  background-color: #f2f2f2;
}

.returns-heatmap td {
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ReturnsHeatmap.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,eAAe;AACjB;;AAEA;;EAEE,sBAAsB;EACtB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd","sourcesContent":[".returns-heatmap {\n  margin-top: 20px;\n}\n\n.returns-heatmap .table-container {\n  max-width: 800px;\n  margin: 0 auto;\n  overflow-x: auto;\n}\n\n.returns-heatmap table {\n  border-collapse: collapse;\n  width: 100%;\n  font-size: 12px;\n}\n\n.returns-heatmap th,\n.returns-heatmap td {\n  border: 1px solid #ddd;\n  padding: 4px;\n  text-align: center;\n}\n\n.returns-heatmap th {\n  background-color: #f2f2f2;\n}\n\n.returns-heatmap td {\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
