import React, { useState, useEffect } from 'react';
import { useStrategyReturns } from '../hooks/useStrategyReturns';
import { CumulativeReturnsChart } from '../components/Dashboard/CumulativeReturnsChart';
import MetricsBox from '../components/Dashboard/MetricsBox';
import AllocationTable from '../components/Dashboard/AllocationTable';
import StrategyExplanation from '../components/Dashboard/StrategyExplanation';
import PerformanceMetrics from '../components/Performance/PerformanceMetrics';
import { getPortfolioMetrics, getPortfolioPositions, getLivePrices } from '../api/strategyService';
import { Sidebar } from '../components/Dashboard/Sidebar';
import '../styles/Dashboard.css';
import Chat from './Chat';

const Dashboard = ({ setToken }) => {
  const [activeMenuItem, setActiveMenuItem] = useState('dashboard');
  const { strategyReturns, isLoading } = useStrategyReturns();
  const [metrics, setMetrics] = useState([]);
  const [allocations, setAllocations] = useState([]);
  const [timeRange, setTimeRange] = useState('ALL');
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [token, setTokenState] = useState(localStorage.getItem('token'));
  const [livePrices, setLivePrices] = useState({});

  console.log(username);
  console.log(activeMenuItem);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [metricsData, positionsData, pricesData] = await Promise.all([
          getPortfolioMetrics(),
          getPortfolioPositions(),
          getLivePrices()
        ]);
        setMetrics(metricsData);
        setAllocations(positionsData);
        setLivePrices(pricesData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [token]);

  const timeRangeOptions = ['YTD', 'MTD', '1M', '3M', '6M', '1Y', '5Y', 'ALL'];

  return (
    <div className="dashboard">
      <Sidebar 
        username={username} 
        setToken={setTokenState} 
        activeMenuItem={activeMenuItem}
        setActiveMenuItem={setActiveMenuItem}
      />
      <div className="content">
        {<Chat />}
        {activeMenuItem === 'dashboard' && (
          <div className="chart-section">
            <PerformanceMetrics />
            
            <h2>Cumulative Strategy Returns<sup>1</sup></h2>
            <div className="time-range-selector">
              {timeRangeOptions.map(option => (
                <button 
                  key={option}
                  onClick={() => setTimeRange(option)}
                  className={timeRange === option ? 'active' : ''}
                >
                  {option}
                </button>
              ))}
            </div>
            <CumulativeReturnsChart strategyReturns={strategyReturns} timeRange={timeRange} />
            <div className="chart-explanation">
              {/* Chart explanation content */}
            </div>
            <MetricsBox metrics={metrics} />
            <AllocationTable allocations={allocations} livePrices={livePrices} />
            <StrategyExplanation />
          </div>
        )}
        
        <footer className="dashboard-footer">
          <p>¹ All returns are hypothetical and based on backtested data. Past performance does not guarantee future results.</p>
        </footer>
      </div>
    </div>
  );
};

export default Dashboard;