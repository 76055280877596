import axios from '../utils/axiosConfig';

const api = axios.create({
  baseURL: 'http://localhost:8000/api',
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Token ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Function to get strategy returns
export const getStrategyReturns = async () => {
  try {
    const response = await axios.get('/portfolio_returns/');
    return response.data;
  } catch (error) {
    console.error('Error fetching strategy returns:', error);
    throw error;
  }
};

export const getPortfolioMetrics = async () => {
  try {
    const response = await axios.get('/portfolio_metrics/');
    return response.data;
  } catch (error) {
    console.error('Error fetching portfolio metrics:', error);
    throw error;
  }
};

export const getPortfolioPositions = async () => {
  try {
    const response = await axios.get('/portfolio_positions/');
    return response.data;
  } catch (error) {
    console.error('Error fetching portfolio positions:', error);
    throw error;
  }
};

export const getStrategyMetrics = async () => {
  try {
    const response = await axios.get('/strategy_metrics/');
    return response.data;
  } catch (error) {
    console.error('Error fetching strategy metrics:', error);
    throw error;
  }
};

export const getLivePrices = async () => {
  try {
    const response = await axios.get('/live_price/');
    return response.data;
  } catch (error) {
    console.error('Error fetching live prices:', error);
    throw error;
  }
};
