import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

const DailyReturnsDistribution = ({ strategyReturns }) => {
  const { chartData, categories, minReturn, maxReturn, var5, latestReturn } = useMemo(() => {
    if (!strategyReturns || !strategyReturns.returns) return { chartData: [], categories: [], minReturn: 0, maxReturn: 0, var5: 0, latestReturn: 0 };

    const returns = Object.values(strategyReturns.returns);
    const binSize = 0.001; // 0.3% bin size
    const minReturn = Math.floor(Math.min(...returns) / binSize) * binSize;
    const maxReturn = Math.ceil(Math.max(...returns) / binSize) * binSize;

    // Calculate VaR (5th percentile)
    const sortedReturns = [...returns].sort((a, b) => a - b);
    const var5 = sortedReturns[Math.floor(returns.length * 0.05)];

    // Get latest return
    const latestReturn = returns[returns.length - 1];

    const bins = {};
    const categories = [];
    for (let i = minReturn; i <= maxReturn; i += binSize) {
      const key = i.toFixed(3);
      bins[key] = 0;
      categories.push(parseFloat(key));
    }

    returns.forEach(ret => {
      const binIndex = (Math.floor(ret / binSize) * binSize).toFixed(3);
      bins[binIndex]++;
    });

    const chartData = Object.entries(bins).map(([bin, count]) => ({
      x: parseFloat(bin),
      y: count === 0 ? null : count // Set count to null if it's zero to avoid log(0)
    }));

    return { chartData, categories, minReturn, maxReturn, var5, latestReturn };
  }, [strategyReturns]);

  const options = {
    chart: {
      type: 'bar',
      height: 390
    },
    title: {
      text: 'Daily Returns Distribution (Log Scale)',
      align: 'left'
    },
    xaxis: {
      categories: categories,
      tickAmount: 10,
      labels: {
        formatter: function(val) {
          return (val * 100).toFixed(1) + '%';
        }
      }
    },
    yaxis: {
      title: {
        text: 'Frequency (Log Scale)'
      },
      logarithmic: true,
      forceNiceScale: true,
      labels: {
        formatter: function(val) {
          return Math.round(val);
        }
      }
    },
    annotations: {
      xaxis: [
        {
          x: var5,
          borderColor: '#FF4560',
          label: {
            borderColor: '#FF4560',
            style: {
              color: '#fff',
              background: '#FF4560'
            },
            text: `VaR (5%): ${(var5 * 100).toFixed(2)}%`
          }
        },
        {
          x: latestReturn,
          borderColor: '#1E90FF', // Changed to DodgerBlue
          label: {
            borderColor: '#1E90FF', // Changed to DodgerBlue
            style: {
              color: '#fff',
              background: '#1E90FF' // Changed to DodgerBlue
            },
            text: `Latest Return: ${(latestReturn * 100).toFixed(2)}%`
          }
        }
      ]
    },
    tooltip: {
      x: {
        formatter: function(val) {
          return (val * 100).toFixed(2) + '%';
        }
      },
      y: {
        formatter: function(val) {
          return Math.round(val);
        }
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '100%',
        colors: {
          ranges: [{
            from: -Infinity,
            to: 0,
            color: '#FF4560'
          }, {
            from: 0,
            to: Infinity,
            color: '#00E396'
          }]
        }
      }
    },
    dataLabels: {
      enabled: false
    }
  };

  return (
    <div className="chart-container">
      <ReactApexChart options={options} series={[{name: 'Frequency', data: chartData}]} type="bar" height={350} />
    </div>
  );
};

export default DailyReturnsDistribution;