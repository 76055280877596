import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

const SuccessContainer = styled.div`
  text-align: center;
  padding: 2rem;
`;

const PaymentSuccess = ({ setToken }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isUpgrade = searchParams.get('upgrade') === 'true';

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setToken(token);
      navigate('/dashboard');
    } else {
      navigate('/login');
    }
  }, [navigate, setToken]);

  return (
    <SuccessContainer>
      <h2>{isUpgrade ? 'Upgrade Successful!' : 'Setting up your account...'}</h2>
      <p>Please wait while we redirect you to your {isUpgrade ? 'dashboard' : 'login'}.</p>
    </SuccessContainer>
  );
};

export default PaymentSuccess;
