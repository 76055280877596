import React, { useState, useEffect } from 'react';
import '../../styles/AllocationTable.css';

const AllocationTable = ({ allocations, livePrices }) => {
    const [aum, setAum] = useState('');
    const [shares, setShares] = useState({});

    useEffect(() => {
        if (aum && allocations.length > 0 && livePrices) {
            const newShares = {};
            allocations.forEach(item => {
                const price = livePrices[item.ticker]?.currentPrice || 0;
                if (price > 0) {
                    const shareCount = Math.floor((parseFloat(aum) * item.allocation / 100) / price);
                    newShares[item.ticker] = shareCount;
                } else {
                    newShares[item.ticker] = 0;
                }
            });
            setShares(newShares);
        }
    }, [aum, allocations, livePrices]);

    const handleAumChange = (e) => {
        const value = e.target.value.replace(/[^0-9.]/g, '');
        setAum(value);
    };

    const getChangeClass = (percentChange) => {
        if (percentChange > 0) return 'change-up';
        if (percentChange < 0) return 'change-down';
        return 'change-neutral';
    };

    const getChangeSymbol = (percentChange) => {
        if (percentChange > 0) return '▲';
        if (percentChange < 0) return '▼';
        return '–';
    };

    const prepareDataForExport = () => {
        if (!aum) return null;
        return Object.entries(shares).map(([ticker, shareCount]) => ({
            ticker,
            shares: shareCount
        }));
    };

    const downloadJSON = () => {
        const data = prepareDataForExport();
        if (!data) {
            alert("Please enter a portfolio value to calculate shares before downloading.");
            return;
        }
        const jsonString = JSON.stringify(data, null, 2);
        const dataBlob = new Blob([jsonString], { type: 'application/json' });
        const url = URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'portfolio_shares.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <div className="allocation-table-container">
            <h2>Portfolio Allocation</h2>
            <div className="aum-input">
                <label htmlFor="aum">Portfolio Value ($): </label>
                <input
                    type="text"
                    id="aum"
                    className="aum-input-field"
                    value={aum}
                    onChange={handleAumChange}
                    placeholder="Enter portfolio value"
                />
            </div>
            <div className="download-button">
                <button onClick={downloadJSON} disabled={!aum}>Download JSON</button>
            </div>
            <table className="allocation-table">
                <thead>
                    <tr>
                        <th>Ticker</th>
                        <th>Allocation (%)</th>
                        <th>Current Price ($)</th>
                        <th>Change (%)</th>
                        {aum && <th>Shares</th>}
                    </tr>
                </thead>
                <tbody>
                    {allocations.map((item, index) => (
                        <tr key={index}>
                            <td>{item.ticker}</td>
                            <td>{item.allocation.toFixed(2)}%</td>
                            <td>{livePrices[item.ticker]?.currentPrice.toFixed(2) || 'N/A'}</td>
                            <td className={getChangeClass(livePrices[item.ticker]?.percentChange)}>
                                <span className="change-symbol">{getChangeSymbol(livePrices[item.ticker]?.percentChange)}</span>
                                {Math.abs(livePrices[item.ticker]?.percentChange).toFixed(2) || 'N/A'}%
                            </td>
                            {aum && <td>{shares[item.ticker] || 0}</td>}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AllocationTable;