// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.strategies-table-container {
  overflow-x: auto;
}

.strategies-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.strategies-table th,
.strategies-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.strategies-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.strategies-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.strategies-table tr:hover {
  background-color: #f5f5f5;
}
`, "",{"version":3,"sources":["webpack://./src/styles/StrategiesTable.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;;EAEE,sBAAsB;EACtB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".strategies-table-container {\n  overflow-x: auto;\n}\n\n.strategies-table {\n  width: 100%;\n  border-collapse: collapse;\n  margin-top: 20px;\n}\n\n.strategies-table th,\n.strategies-table td {\n  border: 1px solid #ddd;\n  padding: 12px;\n  text-align: left;\n}\n\n.strategies-table th {\n  background-color: #f2f2f2;\n  font-weight: bold;\n}\n\n.strategies-table tr:nth-child(even) {\n  background-color: #f9f9f9;\n}\n\n.strategies-table tr:hover {\n  background-color: #f5f5f5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
