// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* frontend/src/styles/StrategyExplanation.css */
.strategy-explanation {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 20px;
  margin-top: 30px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.strategy-explanation h2 {
  color: #343a40;
  margin-top: 0;
  margin-bottom: 15px;
}

.strategy-explanation p {
  color: #495057;
  line-height: 1.6;
  margin-bottom: 15px;
}

.strategy-explanation ul {
  color: #495057;
  padding-left: 20px;
  margin-bottom: 15px;
}

.strategy-explanation li {
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/StrategyExplanation.css"],"names":[],"mappings":"AAAA,gDAAgD;AAChD;EACE,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,qCAAqC;AACvC;;AAEA;EACE,cAAc;EACd,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["/* frontend/src/styles/StrategyExplanation.css */\n.strategy-explanation {\n  background-color: #f8f9fa;\n  border: 1px solid #e9ecef;\n  border-radius: 8px;\n  padding: 20px;\n  margin-top: 30px;\n  box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n}\n\n.strategy-explanation h2 {\n  color: #343a40;\n  margin-top: 0;\n  margin-bottom: 15px;\n}\n\n.strategy-explanation p {\n  color: #495057;\n  line-height: 1.6;\n  margin-bottom: 15px;\n}\n\n.strategy-explanation ul {\n  color: #495057;\n  padding-left: 20px;\n  margin-bottom: 15px;\n}\n\n.strategy-explanation li {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
