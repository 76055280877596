import React from 'react';
import '../../styles/MetricsBox.css';

const MetricsBox = ({ metrics }) => {
    const formatValue = (key, value) => {
        const percentageMetrics = ['CAGR', 'Max Drawdown', 'Volatility'];
        if (percentageMetrics.includes(key) && typeof value === 'number') {
            return `${(value * 100).toFixed(2)}%`;
        }
        return typeof value === 'number' ? value.toFixed(2) : value;
    };

    const renderMetrics = () => {
        if (Array.isArray(metrics)) {
            return metrics.map((metric, index) => (
                <div key={index} className="metric-box">
                    <h3>{metric.label}</h3>
                    <p>{formatValue(metric.label, metric.value)}</p>
                </div>
            ));
        } else if (typeof metrics === 'object' && metrics !== null) {
            return Object.entries(metrics).map(([key, value], index) => (
                <div key={index} className="metric-box">
                    <h3>{key}</h3>
                    <p>{formatValue(key, value)}</p>
                </div>
            ));
        } else {
            return <div>No metrics available</div>;
        }
    };

    return (
        <div className="metrics-container">
            {renderMetrics()}
        </div>
    );
};

export default MetricsBox;