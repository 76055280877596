// src/pages/Settings.js
import React, { useState, useEffect } from 'react';
import { Sidebar } from '../components/Dashboard/Sidebar';
import { getUserProfile, updateUserProfile, changePassword } from '../api/userService';
import '../styles/Dashboard.css';
import '../styles/Settings.css';
import { loginInstance } from '../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import { FaCreditCard, FaRegClock, FaUser, FaLock, FaKey, FaArrowUp, FaArrowDown, FaTimes } from 'react-icons/fa';
import styled from 'styled-components';

const Section = styled.div`
  background: white;
  border-radius: 10px;
  padding: 3rem;
  margin-bottom: 2.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const SubscriptionSection = styled(Section)`
  margin-top: 2.5rem;
`;

const UpgradeMessage = styled.div`
  margin-top: 1.5rem;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 8px;
  text-align: center;
  
  h4 {
    color: #2c3e50;
    margin-bottom: 1rem;
  }
  
  p {
    color: #666;
    margin-bottom: 1.5rem;
  }
`;

const UpgradeButton = styled.button`
  background: #3498db;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  
  &:hover {
    background: #2980b9;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  
  h3 {
    margin: 0;
    color: #2c3e50;
    font-size: 1.8rem;
  }
  
  svg {
    margin-right: 15px;
    color: #3498db;
    font-size: 1.5rem;
  }
`;

const SubscriptionHeader = styled(SectionHeader)`
  // Add any subscription-specific header styles here if needed
`;

const SubscriptionInfo = styled.div`
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 8px;
  
  p {
    margin: 0.75rem 0;
    color: #666;
    font-size: 1.1rem;
  }
  
  strong {
    color: #2c3e50;
  }
`;

const CancelButton = styled.button`
  background-color: #fff;
  color: #dc3545;
  border: 2px solid #dc3545;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: #dc3545;
    color: white;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
  
  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #2c3e50;
    font-weight: 500;
  }
  
  input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    
    &:disabled {
      background: #f8f9fa;
    }
  }
`;

const SubmitButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #2980b9;
  }
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const Message = styled.div`
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 5px;
  
  &.error {
    color: #dc3545;
    background: #fff5f5;
  }
  
  &.success {
    color: #28a745;
    background: #f0fff4;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
`;

const PlanChangeButton = styled.button`
  background-color: #fff;
  color: ${props => props.variant === 'upgrade' ? '#3498db' : '#f39c12'};
  border: 2px solid ${props => props.variant === 'upgrade' ? '#3498db' : '#f39c12'};
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background-color: ${props => props.variant === 'upgrade' ? '#3498db' : '#f39c12'};
    color: white;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const Settings = ({ setToken }) => {
  const [activeMenuItem, setActiveMenuItem] = useState('settings');
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [profileError, setProfileError] = useState('');
  const [profileSuccess, setProfileSuccess] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');
  const [userToken, setUserToken] = useState('');
  const [isUnsubscribing, setIsUnsubscribing] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    planName: '',
    status: '',
    billingCycle: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserProfile();
    fetchSubscriptionDetails();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const userData = await getUserProfile();
      setUsername(userData.username);
      setEmail(userData.email);
      setUserToken(localStorage.getItem('token') || '');
    } catch (error) {
      console.error('Failed to fetch user profile:', error);
      setProfileError('Failed to load user profile');
    }
  };

  const fetchSubscriptionDetails = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await loginInstance.get('/payments/subscription-details/', {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      setSubscriptionDetails(response.data);
    } catch (error) {
      console.error('Failed to fetch subscription details:', error);
      setProfileError('Failed to load subscription details');
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    setProfileError('');
    setProfileSuccess('');

    try {
      await updateUserProfile({ username, email });
      setProfileSuccess('Profile updated successfully');
    } catch (error) {
      setProfileError(error.message || 'Failed to update profile');
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setPasswordError('');
    setPasswordSuccess('');

    if (newPassword !== confirmPassword) {
      setPasswordError("New passwords don't match");
      return;
    }

    try {
      const token = localStorage.getItem('token');
      
      await loginInstance.post('/auth/change-password/', {
        current_password: currentPassword,
        new_password: newPassword
      }, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });

      setPasswordSuccess('Password changed successfully');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      console.error('Password change error:', error.response?.data);
      
      // Handle validation errors
      if (error.response?.data) {
        const errors = error.response.data;
        if (errors.new_password) {
          // Join multiple error messages if they exist
          setPasswordError(errors.new_password.join(' '));
        } else if (errors.current_password) {
          setPasswordError(errors.current_password.join(' '));
        } else if (errors.error) {
          setPasswordError(errors.error);
        } else {
          setPasswordError('Failed to change password');
        }
      } else {
        setPasswordError('Failed to change password');
      }
    }
  };

  const handleUnsubscribe = async () => {
    if (!window.confirm('Are you sure you want to cancel your subscription?')) {
      return;
    }

    setIsUnsubscribing(true);
    setProfileError('');
    setProfileSuccess('');

    try {
      const token = localStorage.getItem('token');
      
      await loginInstance.post('/payments/cancel-subscription/', {}, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });
      
      // Handle logout
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      setToken(null);
      navigate('/login');
      
    } catch (error) {
      console.error('Cancellation error:', error);
      setProfileError(error.response?.data?.error || 'Failed to cancel subscription');
      setIsUnsubscribing(false);
    }
  };

  return (
    <div className="dashboard">
      <Sidebar 
        username={username}
        setToken={setToken}
        activeMenuItem={activeMenuItem}
        setActiveMenuItem={setActiveMenuItem}
      />
      <div className="content">
        <h1>Account Settings</h1>
        <div className="settings-content">
          <Section>
            <SectionHeader>
              <FaUser size={24} />
              <h3>Profile Information</h3>
            </SectionHeader>
            {profileError && <Message className="error">{profileError}</Message>}
            {profileSuccess && <Message className="success">{profileSuccess}</Message>}
            <form onSubmit={handleUpdateProfile}>
              <FormGroup>
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="email">Email</label>
                <input 
                  type="email" 
                  id="email" 
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)} 
                />
              </FormGroup>
              <SubmitButton type="submit">Update Profile</SubmitButton>
            </form>
          </Section>

          <Section>
            <SectionHeader>
              <FaLock size={24} />
              <h3>Change Password</h3>
            </SectionHeader>
            {passwordError && <Message className="error">{passwordError}</Message>}
            {passwordSuccess && <Message className="success">{passwordSuccess}</Message>}
            <form onSubmit={handleChangePassword}>
              <FormGroup>
                <label htmlFor="currentPassword">Current Password</label>
                <input
                  type="password"
                  id="currentPassword"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="newPassword">New Password</label>
                <input
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </FormGroup>
              <FormGroup>
                <label htmlFor="confirmPassword">Confirm New Password</label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </FormGroup>
              <SubmitButton type="submit">Change Password</SubmitButton>
            </form>
          </Section>

          <Section>
            <SectionHeader>
              <FaKey size={24} />
              <h3>Your API Token</h3>
            </SectionHeader>
            <p style={{ color: '#666', marginBottom: '1rem' }}>
              This is your personal API token. Keep it secure and do not share it.
            </p>
            <FormGroup>
              <input 
                type="text" 
                value={userToken} 
                readOnly 
                className="token-display"
              />
            </FormGroup>
            <SubmitButton onClick={() => navigator.clipboard.writeText(userToken)}>
              Copy to Clipboard
            </SubmitButton>
          </Section>

          <SubscriptionSection>
            <SubscriptionHeader>
              <FaCreditCard size={24} />
              <h3>Subscription Management</h3>
            </SubscriptionHeader>
            
            <SubscriptionInfo>
              <p>
                <strong>Current Plan:</strong>{' '}
                {subscriptionDetails.planName || 'Loading...'}
              </p>
              <p>
                <strong>Status:</strong>{' '}
                <span style={{ 
                  color: subscriptionDetails.status === 'active' ? '#28a745' : '#dc3545'
                }}>
                  {subscriptionDetails.status ? 
                    subscriptionDetails.status.charAt(0).toUpperCase() + 
                    subscriptionDetails.status.slice(1) 
                    : 'Loading...'}
                </span>
              </p>
              <p>
                <FaRegClock style={{ marginRight: '5px' }} />
                <strong>Billing Cycle:</strong>{' '}
                {subscriptionDetails.billingCycle || 'Loading...'}
              </p>
            </SubscriptionInfo>

            {profileError && (
              <Message className="error">{profileError}</Message>
            )}
            
            {profileSuccess && (
              <Message className="success">{profileSuccess}</Message>
            )}

            <ActionButtons>
              {subscriptionDetails.planName?.toLowerCase() === 'basic' && (
                <PlanChangeButton 
                  variant="upgrade"
                  onClick={() => navigate('/payment')}
                >
                  <FaArrowUp /> Upgrade to Advanced
                </PlanChangeButton>
              )}
              
              {subscriptionDetails.planName?.toLowerCase() === 'advanced' && (
                <PlanChangeButton 
                  variant="downgrade"
                  onClick={() => navigate('/payment')}
                >
                  <FaArrowDown /> Downgrade to Basic
                </PlanChangeButton>
              )}

              <CancelButton 
                onClick={handleUnsubscribe}
                disabled={isUnsubscribing}
              >
                {isUnsubscribing ? (
                  <>
                    <span className="spinner-border spinner-border-sm" />
                    Processing...
                  </>
                ) : (
                  <>
                    <FaTimes /> Cancel Subscription
                  </>
                )}
              </CancelButton>
            </ActionButtons>
          </SubscriptionSection>
        </div>
      </div>
    </div>
  );
};

export default Settings;
