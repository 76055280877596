import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

const RollingSharpeChart = ({ rollingSharpe, startDate, endDate }) => {
  const chartData = useMemo(() => {
    if (!rollingSharpe || typeof rollingSharpe !== 'object') {
      console.error('Invalid data format for rolling Sharpe ratio');
      return [];
    }

    const start = new Date(startDate);
    const end = new Date(endDate);

    let filteredData = Object.entries(rollingSharpe.returns)
      .filter(([date]) => {
        const currentDate = new Date(date);
        return currentDate >= start && currentDate <= end;
      })
      .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB));

    console.log('Filtered Rolling Sharpe Data:', filteredData);

    if (filteredData.length === 0) {
      console.error('No data available for the selected date range');
      return [];
    }

    return filteredData.map(([date, value]) => ({
      x: new Date(date).getTime(),
      y: value
    }));
  }, [rollingSharpe, startDate, endDate]);

  const options = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false
      }
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    title: {
      text: 'Rolling Sharpe Ratio',
      align: 'left'
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      title: {
        text: 'Sharpe Ratio'
      },
      labels: {
        formatter: (value) => value.toFixed(2)
      }
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'
      },
      y: {
        formatter: (value) => value.toFixed(2)
      }
    }
  };

  const series = [
    {
      name: 'Rolling Sharpe Ratio',
      data: chartData
    }
  ];

  if (chartData.length === 0) {
    return <div>No data available for the selected date range</div>;
  }

  return (
    <div className="chart-container">
      <ReactApexChart 
        options={options} 
        series={series} 
        type="line" 
        height={350} 
      />
    </div>
  );
};

export default RollingSharpeChart;
