import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

export const CumulativeReturnsBarChart = ({ strategyReturns, spyReturns, startDate, endDate }) => {
  const chartData = useMemo(() => {
    if (!strategyReturns || !spyReturns || typeof strategyReturns !== 'object' || typeof spyReturns !== 'object') {
      return [];
    }

    const calculateAnnualReturn = (returns, isStrategy = false) => {
      const annualReturns = {};
      const returnsData = isStrategy ? (returns.returns || returns) : returns;

      Object.entries(returnsData).forEach(([date, returnValue]) => {
        const currentDate = new Date(date);
        const year = currentDate.getFullYear();
        
        if (currentDate >= new Date(startDate) && currentDate <= new Date(endDate)) {
          if (!annualReturns[year]) {
            annualReturns[year] = 1;
          }
          annualReturns[year] *= (1 + returnValue);
        }
      });

      return Object.entries(annualReturns).map(([year, totalReturn]) => ({
        year: parseInt(year),
        return: (totalReturn - 1) * 100
      }));
    };

    const strategyAnnualReturns = calculateAnnualReturn(strategyReturns, true);
    const spyAnnualReturns = calculateAnnualReturn(spyReturns);

    const startYear = new Date(startDate).getFullYear();
    const endYear = new Date(endDate).getFullYear();

    return Array.from({ length: endYear - startYear + 1 }, (_, index) => {
      const year = startYear + index;
      const strategyReturn = strategyAnnualReturns.find(item => item.year === year)?.return || 0;
      const spyReturn = spyAnnualReturns.find(item => item.year === year)?.return || 0;
      return { year, strategyReturn, spyReturn };
    });
  }, [strategyReturns, spyReturns, startDate, endDate]);

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: { show: false }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: { enabled: false },
    stroke: { show: true, width: 2, colors: ['transparent'] },
    title: { text: 'Annual Returns Comparison', align: 'left' },
    xaxis: {
      type: 'category',
      categories: chartData.map(item => item.year.toString()),
      title: { text: 'Year' },
      labels: {
        rotate: -45,
        rotateAlways: false,
        formatter: function(val) {
          return val.toString();
        }
      }
    },
    yaxis: {
      title: { text: 'Annual Return (%)' },
      labels: { formatter: (value) => `${value.toFixed(2)}%` }
    },
    fill: { opacity: 0.8 },
    legend: { position: 'top' },
    tooltip: {
      y: {
        formatter: (value) => `${value.toFixed(2)}%`
      }
    }
  };

  const series = [
    {
      name: 'Strategy',
      data: chartData.map(item => ({
        x: item.year,
        y: item.strategyReturn
      }))
    },
    {
      name: 'S&P 500',
      data: chartData.map(item => ({
        x: item.year,
        y: item.spyReturn
      }))
    }
  ];

  if (chartData.length === 0) {
    return <div>No data available for the selected date range</div>;
  }

  return (
    <div className="chart-container">
      <ReactApexChart 
        options={options} 
        series={series} 
        type="bar" 
        height={350} 
      />
    </div>
  );
};

export default CumulativeReturnsBarChart;
