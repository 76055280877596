import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FaTimesCircle } from 'react-icons/fa';

const CancelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  padding: 2rem;
  text-align: center;
`;

const Icon = styled(FaTimesCircle)`
  color: #dc3545;
  font-size: 4rem;
  margin-bottom: 1.5rem;
`;

const Title = styled.h2`
  color: #2c3e50;
  margin-bottom: 1rem;
`;

const Message = styled.p`
  color: #666;
  margin-bottom: 2rem;
  font-size: 1.1rem;
`;

const Button = styled.button`
  background: #3498db;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  
  &:hover {
    background: #2980b9;
  }
`;

const PaymentCancel = () => {
  const navigate = useNavigate();

  return (
    <CancelContainer>
      <Icon />
      <Title>Payment Cancelled</Title>
      <Message>
        Your payment was not processed. If you experienced any issues,
        please try again or contact our support team.
      </Message>
      <Button onClick={() => navigate('/payment')}>
        Try Again
      </Button>
    </CancelContainer>
  );
};

export default PaymentCancel;
