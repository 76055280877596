import React, { useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { loginInstance } from '../../utils/axiosConfig';
import styled from 'styled-components';
import registerBackgroundImage from '../../images/register-background.jpg';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const RegisterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
`;

const ImageSection = styled.div`
  background-image: url(${registerBackgroundImage});
  background-size: cover;
  background-position: center;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #f8f9fa;
`;

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 400px;
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  margin-top: 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-weight: bold;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
  }
`;

const PlanBadge = styled.div`
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  display: inline-block;
`;

const PlanPrice = styled.div`
  font-size: 1.2rem;
  color: #28a745;
  margin-bottom: 1.5rem;
`;

const Register = () => {
  const location = useLocation();
  const planId = new URLSearchParams(location.search).get('plan') || 'basic';
  
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const planDetails = {
    basic: {
      name: 'Basic Plan',
      price: '$45/month',
      priceId: 'price_1QFOJmFA86XYQYo1pEbXJ1di'
    },
    pro: {
      name: 'Pro Plan',
      price: '$95/month',
      priceId: 'price_1QFOSqFA86XYQYo1Hlz1QOO7'
    }
  };

  const currentPlan = planDetails[planId] || planDetails.basic;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const checkoutResponse = await loginInstance.post('/payments/create-checkout-session/', {
        email,
        username,
        password,
        priceId: currentPlan.priceId
      });

      const { sessionId } = checkoutResponse.data;
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred');
      setIsLoading(false);
    }
  };

  return (
    <RegisterContainer>
      <ImageSection />
      <FormSection>
        <h2 className="text-center mb-4">Create an Account</h2>
        <PlanBadge>{currentPlan.name}</PlanBadge>
        <PlanPrice>{currentPlan.price}</PlanPrice>
        {error && <Alert variant="danger">{error}</Alert>}
        <StyledForm onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>
          <StyledButton type="submit" disabled={isLoading}>
            {isLoading ? 'Processing...' : `Register & Pay`}
          </StyledButton>
        </StyledForm>
        <div className="text-center mt-3">
          Already have an account? <Link to="/login">Login</Link>
        </div>
        <div className="text-center mt-2">
          Want a different plan?{' '}
          {planId === 'pro' ? (
            <Link to="/register?plan=basic">Switch to Basic Plan</Link>
          ) : (
            <Link to="/register?plan=pro">Switch to Pro Plan</Link>
          )}
        </div>
      </FormSection>
    </RegisterContainer>
  );
};

export default Register;
