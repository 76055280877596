import React, { useState, useEffect } from 'react';
import { getStrategyMetrics } from '../../api/strategyService';
import '../../styles/StrategiesTable.css';

const StrategiesTable = () => {
  const [strategies, setStrategies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStrategies = async () => {
      try {
        const data = await getStrategyMetrics();
        const formattedStrategies = Object.entries(data).map(([name, metrics]) => ({
          name,
          ...metrics
        }));
        setStrategies(formattedStrategies);
        setIsLoading(false);
      } catch (err) {
        setError('Failed to fetch strategy metrics');
        setIsLoading(false);
      }
    };

    fetchStrategies();
  }, []);

  if (isLoading) return <div>Loading strategies...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="strategies-table-container">
      <table className="strategies-table">
        <thead>
          <tr>
            <th>Strategy</th>
            <th>Sharpe</th>
            <th>CAGR</th>
            <th>Max Drawdown</th>
            <th>Volatility</th>
          </tr>
        </thead>
        <tbody>
          {strategies.map((strategy) => (
            <tr key={strategy.name}>
              <td>{strategy.name}</td>
              <td>{strategy.sharpe.toFixed(2)}</td>
              <td>{(strategy.cagr * 100).toFixed(2)}%</td>
              <td>{(strategy.max_drawdown * 100).toFixed(2)}%</td>
              <td>{(strategy.volatility * 100).toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StrategiesTable;