// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.performance-metrics {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
}

.metric {
  text-align: center;
}

.metric h3 {
  margin-bottom: 5px;
  font-size: 14px;
  color: #666;
}

.metric p {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/styles/PerformanceMetrics.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;AACb","sourcesContent":[".performance-metrics {\n  display: flex;\n  justify-content: space-around;\n  margin-bottom: 20px;\n  background-color: #f5f5f5;\n  padding: 15px;\n  border-radius: 8px;\n}\n\n.metric {\n  text-align: center;\n}\n\n.metric h3 {\n  margin-bottom: 5px;\n  font-size: 14px;\n  color: #666;\n}\n\n.metric p {\n  font-size: 18px;\n  font-weight: bold;\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
