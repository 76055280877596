import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL || '';
const complexPath = process.env.REACT_APP_API_COMPLEX_PATH || '';

const instance = axios.create({
  baseURL: `${baseURL}/${complexPath}`,
});

// Create a separate instance for login
export const loginInstance = axios.create({
  baseURL: baseURL,
});

// Add interceptor to loginInstance
loginInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Token ${token}`;  // Using 'Token' prefix to match your setup
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Optional: Add response interceptor for loginInstance
loginInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const authInstance = axios.create({
  baseURL: baseURL,
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Token ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

authInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Token ${token}`;  // Ensure 'Token' prefix is used
    }
    console.log('Request headers:', config.headers);  // Log the headers
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
