import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import styled from 'styled-components';
import { Sidebar } from '../Dashboard/Sidebar';
import { loginInstance } from '../../utils/axiosConfig';
import { getUserProfile } from '../../api/userService';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PricingContainer = styled.div`
  max-width: 500px;
  width: 100%;
  padding: 2rem;
`;

const Title = styled.h2`
  text-align: center;
  color: #2c3e50;
  margin-bottom: 2.5rem;
  font-size: 2rem;
`;

const PlansContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

const PlanCard = styled.div`
  background: white;
  border-radius: 10px;
  padding: 2.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 100%;
  max-width: 450px;
`;

const PlanName = styled.h3`
  text-align: center;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
`;

const PlanPrice = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  
  .amount {
    font-size: 3rem;
    font-weight: bold;
    color: #2c3e50;
  }
  
  .period {
    color: #666;
    font-size: 1.2rem;
  }
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
`;

const Feature = styled.li`
  padding: 0.75rem 0;
  color: #666;
  font-size: 1.1rem;
  text-align: center;
`;

const SubscribeButton = styled.button`
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  background: ${props => props.featured ? '#3498db' : '#2ecc71'};
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 1.1rem;
  
  &:hover {
    background: ${props => props.featured ? '#2980b9' : '#27ae60'};
  }
  
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

const Payment = ({ setToken }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [currentPlan, setCurrentPlan] = useState('');
  const [activeMenuItem, setActiveMenuItem] = useState('payment');

  useEffect(() => {
    fetchSubscriptionDetails();
  }, []);

  const fetchSubscriptionDetails = async () => {
    try {
      const response = await loginInstance.get('/payments/subscription-details/');
      setCurrentPlan(response.data.planName?.toLowerCase());
    } catch (error) {
      console.error('Failed to fetch subscription details:', error);
    }
  };

  const plans = [
    {
      name: 'Basic',
      price: '45',
      priceId: 'price_1QFOJmFA86XYQYo1pEbXJ1di',
      features: [
        'Access to the strategy allocation',
        'Access to the strategy performance',
        'Unlimited API calls'
      ]
    },
    {
      name: 'Advanced',
      price: '95',
      priceId: 'price_1QFOSqFA86XYQYo1Hlz1QOO7',
      featured: true,
      features: [
        'All basic features',
        'Automatization of the strategy execution',
        'Chat support'
      ]
    }
  ];

  // Filter plans to show only the one the user can switch to
  const availablePlan = plans.find(plan => 
    currentPlan === 'advanced' ? 
    plan.name.toLowerCase() === 'basic' : 
    plan.name.toLowerCase() === 'advanced'
  );

  const handleSubscribe = async (priceId) => {
    try {
      setIsLoading(true);
      const stripe = await stripePromise;
      
      const response = await loginInstance.post('/payments/upgrade-subscription/', {
        priceId: priceId
      });
      
      const { sessionId } = response.data;
      
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });

      if (error) {
        console.error('Error:', error);
      }
    } catch (error) {
      console.error('Payment error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageContainer>
      <Sidebar 
        username={username}
        setToken={setToken}
        activeMenuItem={activeMenuItem}
        setActiveMenuItem={setActiveMenuItem}
      />
      <ContentContainer>
        <PricingContainer>
          <Title>
            {currentPlan === 'advanced' ? 'Downgrade Your Plan' : 'Upgrade Your Plan'}
          </Title>
          <PlansContainer>
            {availablePlan && (
              <PlanCard key={availablePlan.name} featured={availablePlan.featured}>
                <PlanName>{availablePlan.name}</PlanName>
                <PlanPrice>
                  <span className="amount">${availablePlan.price}</span>
                  <span className="period">/month</span>
                </PlanPrice>
                <FeaturesList>
                  {availablePlan.features.map((feature) => (
                    <Feature key={feature}>{feature}</Feature>
                  ))}
                </FeaturesList>
                <SubscribeButton
                  featured={availablePlan.featured}
                  onClick={() => handleSubscribe(availablePlan.priceId)}
                  disabled={isLoading}
                >
                  {isLoading ? 'Processing...' : 
                    currentPlan === 'advanced' ? 'Downgrade Now' : 'Upgrade Now'
                  }
                </SubscribeButton>
              </PlanCard>
            )}
          </PlansContainer>
        </PricingContainer>
      </ContentContainer>
    </PageContainer>
  );
};

export default Payment;
