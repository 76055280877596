import React, { useState, useEffect } from 'react';
import { fetchStrategyReturns } from '../../api/performanceApi';
import '../../styles/PerformanceMetrics.css';

const PerformanceMetrics = () => {
  const [metrics, setMetrics] = useState({
    dailyReturn: null,
    mtdReturn: null,
    ytdReturn: null,
  });

  useEffect(() => {
    const fetchMetrics = async () => {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const startOfYear = new Date(today.getFullYear(), 0, 1);

      try {
        console.log('Fetching data for performance metrics...');
        const allData = await fetchStrategyReturns('1980-01-01', today.toISOString().split('T')[0]);
        
        console.log('All data:', allData);

        const dailyData = filterDataByDateRange(allData.returns, yesterday, today);
        const mtdData = filterDataByDateRange(allData.returns, startOfMonth, today);
        const ytdData = filterDataByDateRange(allData.returns, startOfYear, today);

        console.log('Filtered daily data:', dailyData);
        console.log('Filtered MTD data:', mtdData);
        console.log('Filtered YTD data:', ytdData);

        const dailyReturn = calculateReturn(dailyData);
        const mtdReturn = calculateReturn(mtdData);
        const ytdReturn = calculateReturn(ytdData);

        console.log('Calculated returns:', { dailyReturn, mtdReturn, ytdReturn });

        setMetrics({ dailyReturn, mtdReturn, ytdReturn });
      } catch (error) {
        console.error('Error fetching performance metrics:', error);
      }
    };

    fetchMetrics();
  }, []);

  const filterDataByDateRange = (data, startDate, endDate) => {
    return Object.entries(data).reduce((filtered, [date, value]) => {
      const currentDate = new Date(date);
      if (currentDate >= startDate && currentDate <= endDate) {
        filtered[date] = value;
      }
      return filtered;
    }, {});
  };

  const calculateReturn = (data) => {
    if (!data || Object.keys(data).length === 0) {
      console.log('No data available for return calculation');
      return null;
    }
    const returns = Object.values(data);
    console.log('Returns for calculation:', returns);
    const result = returns.reduce((acc, curr) => (1 + acc) * (1 + curr) - 1, 0);
    console.log('Calculated result:', result);
    return result;
  };

  const formatPercentage = (value) => {
    if (value === null) {
      return 'N/A';
    }
    if (isNaN(value)) {
      console.log('NaN value detected:', value);
      return 'Error';
    }
    return `${(value * 100).toFixed(2)}%`;
  };

  return (
    <div className="performance-metrics">
      <div className="metric">
        <h3>Daily Return</h3>
        <p>{formatPercentage(metrics.dailyReturn)}</p>
      </div>
      <div className="metric">
        <h3>MTD Return</h3>
        <p>{formatPercentage(metrics.mtdReturn)}</p>
      </div>
      <div className="metric">
        <h3>YTD Return</h3>
        <p>{formatPercentage(metrics.ytdReturn)}</p>
      </div>
    </div>
  );
};

export default PerformanceMetrics;
