import React, { useState, useEffect } from 'react';
import { Sidebar } from '../components/Dashboard/Sidebar';
import CumulativeReturnsChart from '../components/Performance/CumulativeReturnsChart';
import CumulativeReturnsBarChart from '../components/Performance/CumulativeReturnsBarChart';
import DrawdownChart from '../components/Performance/DrawdownChart';
import RollingSharpeChart from '../components/Performance/RollingSharpeRatio';
import ReturnsHeatmap from '../components/Performance/ReturnsHeatmap';
import DailyReturnsDistribution from '../components/Performance/DailyReturnsDistribution';
import DrawdownAnalysis from '../components/Performance/DrawdownAnalysis';
import { fetchStrategyReturns, fetchSpyReturns, fetchRollingSharpe, fetchPortfolioDrawdown, fetchSpyDrawdown } from '../api/performanceApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import '../styles/Dashboard.css';
import { debounce } from 'lodash';
import styled from 'styled-components';

const DatePickerContainer = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 2rem 0;
  display: flex;
  justify-content: center;  // Center the date inputs
  gap: 2rem;
`;

const DateInputGroup = styled.div`
  width: 250px;  // Fixed width instead of flex
  
  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #2c3e50;
    font-weight: 500;
    font-size: 0.9rem;
  }
`;

const DateInputWrapper = styled.div`
  position: relative;
  
  input {
    width: 100%;
    padding: 0.75rem;
    padding-right: 2.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 1rem;
    transition: all 0.2s ease;
    cursor: pointer;
    
    &:focus {
      outline: none;
      border-color: #3498db;
      box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.1);
    }
    
    &:hover {
      border-color: #cbd5e0;
    }
  }
  
  svg {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #718096;
    pointer-events: none;
    width: 16px;  // Fixed width for the icon
    height: 16px;  // Fixed height for the icon
  }

  /* Hide default calendar icon in Chrome */
  input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }

  /* Hide default calendar icon in Firefox */
  input[type="date"]::-moz-calendar-picker-indicator {
    opacity: 0;
  }
`;

// Add responsive styles
const ResponsiveContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 600px;  // Limit the maximum width
  margin: 0 auto;    // Center the container
`;

const Performance = ({ setToken }) => {
  const [strategyReturns, setStrategyReturns] = useState(null);
  const [spyReturns, setSpyReturns] = useState(null);
  const [rollingSharpe, setRollingSharpe] = useState(null);
  const [strategyDrawdown, setStrategyDrawdown] = useState(null);
  const [spyDrawdown, setSpyDrawdown] = useState(null);
  const [startDate, setStartDate] = useState('2010-01-01');
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [error, setError] = useState(null);
  const [tempStartDate, setTempStartDate] = useState(startDate);
  const [tempEndDate, setTempEndDate] = useState(endDate);
  const [debouncedUpdate] = useState(() => 
    debounce((start, end) => {
      setStartDate(start);
      setEndDate(end);
    }, 500)
  );

  useEffect(() => {
    const loadData = async () => {
      try {
        const [
          strategyData, 
          spyData, 
          sharpeData, 
          strategyDrawdownData,
          spyDrawdownData
        ] = await Promise.all([
          fetchStrategyReturns(startDate, endDate),
          fetchSpyReturns(startDate, endDate),
          fetchRollingSharpe(startDate, endDate),
          fetchPortfolioDrawdown(startDate, endDate),
          fetchSpyDrawdown(startDate, endDate)
        ]);
        
        setStrategyReturns(strategyData);
        setSpyReturns(spyData);
        setRollingSharpe(sharpeData);
        setStrategyDrawdown(strategyDrawdownData);
        setSpyDrawdown(spyDrawdownData);
        setError(null);
      } catch (err) {
        console.error('Error loading data:', err);
        setError('Failed to load data. Please try again.');
      }
    };
    loadData();
  }, [startDate, endDate]);

  // Handle date changes with debouncing
  const handleStartDateChange = (e) => {
    const newDate = e.target.value;
    setTempStartDate(newDate);
    debouncedUpdate(newDate, tempEndDate);
  };

  const handleEndDateChange = (e) => {
    const newDate = e.target.value;
    setTempEndDate(newDate);
    debouncedUpdate(tempStartDate, newDate);
  };

  return (
    <div className="dashboard">
      <Sidebar 
        username={localStorage.getItem('username')} 
        setToken={setToken}
        activeMenuItem="performance"
      />
      <div className="content">
        <h1>Performance Analysis</h1>
        
        <ResponsiveContainer>
          <DatePickerContainer>
            <DateInputGroup>
              <label htmlFor="start-date">From Date</label>
              <DateInputWrapper>
                <input 
                  id="start-date"
                  type="date" 
                  value={tempStartDate} 
                  onChange={handleStartDateChange}
                  max={tempEndDate}
                />
                <FontAwesomeIcon icon={faCalendarAlt} />
              </DateInputWrapper>
            </DateInputGroup>

            <DateInputGroup>
              <label htmlFor="end-date">To Date</label>
              <DateInputWrapper>
                <input 
                  id="end-date"
                  type="date" 
                  value={tempEndDate} 
                  onChange={handleEndDateChange}
                  min={tempStartDate}
                  max={new Date().toISOString().split('T')[0]}
                />
                <FontAwesomeIcon icon={faCalendarAlt} />
              </DateInputWrapper>
            </DateInputGroup>
          </DatePickerContainer>
        </ResponsiveContainer>
        
        {error && <div className="error-message">{error}</div>}
        
        <div className="chart-section">
          {strategyReturns && spyReturns && (
            <>
              <CumulativeReturnsChart 
                strategyReturns={strategyReturns} 
                spyReturns={spyReturns}
                startDate={startDate}
                endDate={endDate}
            />
            <CumulativeReturnsBarChart 
              strategyReturns={strategyReturns} 
              spyReturns={spyReturns}
              startDate={startDate}
              endDate={endDate}
              />
            </>
          )}
        </div>
        
        <div className="chart-section">
          {strategyDrawdown && spyDrawdown && (
            <DrawdownChart 
              strategyDrawdown={strategyDrawdown}
              spyDrawdown={spyDrawdown}
              startDate={startDate}
              endDate={endDate}
            />
          )}
        </div>
        
        <div className="chart-section">
          {strategyReturns && (
            <ReturnsHeatmap 
              strategyReturns={strategyReturns}
              startDate={startDate}
              endDate={endDate}
            />
          )}
        </div>
        
        <div className="chart-section side-by-side">
          {rollingSharpe && (
            <div className="chart-container">
              <RollingSharpeChart 
                rollingSharpe={rollingSharpe}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          )}
          {strategyReturns && (
            <div className="chart-container">
              <DailyReturnsDistribution 
                strategyReturns={strategyReturns}
              />
            </div>
          )}
        </div>
        
        <div className="chart-section">
          {strategyDrawdown && (
            <DrawdownAnalysis drawdownData={strategyDrawdown} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Performance;