import React, { useEffect, useState } from 'react';
import { loginInstance } from '../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const UpgradeMessage = styled.div`
  text-align: center;
  padding: 2rem;
  margin: 2rem auto;
  max-width: 600px;
  
  h2 {
    color: #2c3e50;
    margin-bottom: 1rem;
  }
  
  p {
    color: #666;
    margin-bottom: 1.5rem;
  }
  
  button {
    background: #3498db;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    
    &:hover {
      background: #2980b9;
    }
  }
`;

const Chat = () => {
  const [subscriptionPlan, setSubscriptionPlan] = useState('');
  const navigate = useNavigate();

  // Subscription check effect
  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const response = await loginInstance.get('/payments/subscription-details/');
        setSubscriptionPlan(response.data.planName);
        
        // Load chat widget only if user has advanced plan
        if (response.data.planName?.toLowerCase() === 'advanced') {
          const s1 = document.createElement("script");
          s1.async = true;
          s1.src = 'https://embed.tawk.to/6733c9882480f5b4f59cb488/1ich4mfht';
          s1.charset = 'UTF-8';
          s1.setAttribute('crossorigin', '*');
          document.head.appendChild(s1);
        }
      } catch (error) {
        console.error('Failed to check subscription status:', error);
        navigate('/dashboard');
      }
    };
    
    checkSubscription();

    // Cleanup function
    return () => {
      const chatScript = document.querySelector('script[src*="tawk.to"]');
      if (chatScript) {
        document.head.removeChild(chatScript);
      }
    };
  }, [navigate]);


  return <div id="tawk-root" />;
};

export default Chat;
