import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { loginInstance } from '../../utils/axiosConfig';
import styled from 'styled-components';
import loginBackgroundImage from '../../images/password.jpg';

const ResetPasswordContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
`;

const ImageSection = styled.div`
  background-image: url(${loginBackgroundImage});
  background-size: cover;
  background-position: center;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #f8f9fa;
`;

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 400px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 1rem;
`;

const ResetPassword = () => {
  const [token, setToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    try {
      const response = await loginInstance.post('/auth/reset-password/', { token, new_password: newPassword });
      setMessage(response.data.detail || 'Password reset successfully.');
      setTimeout(() => navigate('/login'), 3000); // Redirect to login page after 3 seconds
    } catch (error) {
      console.error('Password reset failed:', error);
      setError(error.response?.data?.detail || 'Something went wrong. Please try again.');
    }
  };

  return (
    <ResetPasswordContainer>
      <ImageSection />
      <FormSection>
        <h2 className="text-center mb-4">Reset Password</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        {message && <Alert variant="success">{message}</Alert>}
        <StyledForm onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Reset Token</Form.Label>
            <Form.Control
              type="text"
              value={token}
              onChange={(e) => setToken(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </Form.Group>
          <StyledButton variant="primary" type="submit">
            Reset Password
          </StyledButton>
        </StyledForm>
        <div className="text-center mt-3">
          <Link to="/forgot-password">Back to Forgot Password</Link>
        </div>
      </FormSection>
    </ResetPasswordContainer>
  );
};

export default ResetPassword;
