import React from 'react';
import '../../styles/StrategyExplanation.css';

const StrategyExplanation = () => {
  return (
    <div className="strategy-explanation">
      <h2>Strategy Explanation</h2>
      <p>
        Our investment strategy is based on a proprietary algorithm that analyzes market trends, 
        economic indicators, and company fundamentals to optimize portfolio allocation. The strategy 
        aims to maximize returns while managing risk through diversification across different sectors 
        and asset classes.
      </p>
      <ul>
        <li>Dynamic asset allocation based on market conditions</li>
        <li>Risk management through diversification</li>
        <li>Regular rebalancing to maintain optimal portfolio weights</li>
        <li>Incorporation of both fundamental and technical analysis</li>
      </ul>
      <p>
        The performance metrics and allocation shown above are the result of this strategy's 
        application over time. Past performance does not guarantee future results, and the strategy 
        is continuously evaluated and refined to adapt to changing market dynamics.
      </p>
    </div>
  );
};

export default StrategyExplanation;
