// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.metrics-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.metric-box {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 15px;
  width: 22%;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.metric-box h3 {
  margin: 0 0 10px 0;
  font-size: 14px;
  color: #6c757d;
}

.metric-box p {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #495057;
}
`, "",{"version":3,"sources":["webpack://./src/styles/MetricsBox.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,UAAU;EACV,kBAAkB;EAClB,qCAAqC;AACvC;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".metrics-container {\n  display: flex;\n  justify-content: space-between;\n  margin: 20px 0;\n}\n\n.metric-box {\n  background-color: #f8f9fa;\n  border: 1px solid #e9ecef;\n  border-radius: 8px;\n  padding: 15px;\n  width: 22%;\n  text-align: center;\n  box-shadow: 0 2px 4px rgba(0,0,0,0.1);\n}\n\n.metric-box h3 {\n  margin: 0 0 10px 0;\n  font-size: 14px;\n  color: #6c757d;\n}\n\n.metric-box p {\n  margin: 0;\n  font-size: 18px;\n  font-weight: bold;\n  color: #495057;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
