import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { loginInstance } from '../../utils/axiosConfig';
import styled from 'styled-components';
import loginBackgroundImage from '../../images/login-background.jpg';

const LoginContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
`;

const ImageSection = styled.div`
  background-image: url(${loginBackgroundImage});
  background-size: cover;
  background-position: center;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #f8f9fa;
`;

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 400px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 1rem;
`;

const Login = ({ setToken }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      console.log('Attempting to login...');
      const response = await loginInstance.post('/auth/login/', { username, password });
      console.log('Login response:', response);
      const { token, username: responseUsername } = response.data;
      console.log(response.data);
      localStorage.setItem('token', token);
      localStorage.setItem('username', responseUsername);
      setToken(token);
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received:', error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error('Error message:', error.message);
      }
      setError('Unable to connect to the server. Please try again later.');
    }
  };

  return (
    <LoginContainer>
      <ImageSection />
      <FormSection>
        <h2 className="text-center mb-4">Welcome Back</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <StyledForm onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>
          <StyledButton variant="primary" type="submit">
            Login
          </StyledButton>
        </StyledForm>
        <div className="text-center mt-3">
          Don't have an account? <Link to="/register">Register</Link>
          <br />
          <br />
          <Link to="/forgot-password">Forgot Password?</Link>
        </div>
      </FormSection>
    </LoginContainer>
  );
};

export default Login;
