import React, { useMemo } from 'react';
import '../../styles/ReturnsHeatmap.css';

const ReturnsHeatmap = ({ strategyReturns, startDate, endDate }) => {
  const heatmapData = useMemo(() => {
    if (!strategyReturns || !strategyReturns.returns || typeof strategyReturns.returns !== 'object') {
      console.error('Invalid data format for strategy returns');
      return { yearlyData: {}, minReturn: 0, maxReturn: 0 };
    }

    const returns = strategyReturns.returns;
    const yearlyData = {};
    let minReturn = 0;
    let maxReturn = 0;

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    Object.entries(returns).forEach(([date, value]) => {
      const currentDate = new Date(date);
      if (currentDate >= startDateObj && currentDate <= endDateObj) {
        const [year, month] = date.split('-');
        if (!yearlyData[year]) yearlyData[year] = {};
        if (!yearlyData[year][month]) yearlyData[year][month] = 0;
        yearlyData[year][month] += value;

        const monthlyReturn = yearlyData[year][month];
        minReturn = Math.min(minReturn, monthlyReturn);
        maxReturn = Math.max(maxReturn, monthlyReturn);
      }
    });

    return { yearlyData, minReturn, maxReturn };
  }, [strategyReturns, startDate, endDate]);

  const getColor = (value) => {
    if (value === 0) return '#FFFFFF';
    const intensity = Math.min(Math.abs(value) * 5, 1); // Adjust color intensity
    return value > 0 
      ? `rgba(0, 255, 0, ${intensity})` // Green for positive returns
      : `rgba(255, 0, 0, ${intensity})`; // Red for negative returns
  };

  const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const years = Object.keys(heatmapData.yearlyData).sort().reverse();

  return (
    <div className="returns-heatmap">
      <h2>Monthly Returns Heatmap</h2>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Year</th>
              {months.map(month => (
                <th key={month}>{new Date(`2000-${month}-01`).toLocaleString('default', { month: 'short' })}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {years.map(year => (
              <tr key={year}>
                <td>{year}</td>
                {months.map(month => {
                  const value = heatmapData.yearlyData[year]?.[month] || 0;
                  return (
                    <td 
                      key={`${year}-${month}`} 
                      style={{ backgroundColor: getColor(value) }}
                      title={`${year}-${month}: ${(value * 100).toFixed(2)}%`}
                    >
                      {(value * 100).toFixed(2)}%
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReturnsHeatmap;
