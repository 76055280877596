import React from 'react';
import { Sidebar } from '../components/Dashboard/Sidebar';
import StrategiesTable from '../components/Strategies/StrategiesTable';
import '../styles/Dashboard.css';

const Strategies = ({ setToken }) => {
  return (
    <div className="dashboard">
      <Sidebar 
        username={localStorage.getItem('username')} 
        setToken={setToken}
        activeMenuItem="strategies"
      />
      <div className="content">
      <div className="chart-section">
        <h2>Strategies</h2>
        <StrategiesTable />
      </div>
      </div>
    </div>
  );
};

export default Strategies;