import { authInstance } from '../utils/axiosConfig';

export const changePassword = async (currentPassword, newPassword) => {
  try {
    const response = await authInstance.post('/auth/change-password/', {
      current_password: currentPassword,
      new_password: newPassword
    });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('An error occurred while changing the password');
  }
};

export const getUserProfile = async () => {
  try {
    const token = localStorage.getItem('token');
    console.log('Token before request:', token);
    
    const response = await authInstance.get('/auth/profile/');
    console.log('Response:', response);
    return response.data;
  } catch (error) {
    console.error('Error in getUserProfile:', error.response?.data || error.message);
    throw error;
  }
};

export const updateUserProfile = async (userData) => {
  try {
    const response = await authInstance.put('/auth/profile/', userData);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error('An error occurred while updating the user profile');
  }
};

// You can add other user-related API functions here as needed
