import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

export const CumulativeReturnsChart = ({ strategyReturns, timeRange }) => {
  const chartData = useMemo(() => {
    if (!strategyReturns || typeof strategyReturns !== 'object') {
      return [];
    }

    const returnsData = strategyReturns.returns || strategyReturns;
    const now = new Date();
    const startDate = getStartDate(now, timeRange);

    let filteredData = Object.entries(returnsData)
      .filter(([date]) => new Date(date) >= startDate)
      .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB));

    if (filteredData.length === 0) return [];

    if (timeRange === 'ALL') {
      // For 'ALL', we use multiplicative cumulative returns
      let cumulativeReturn = 1;
      return filteredData.map(([date, dailyReturn]) => {
        cumulativeReturn *= (1 + dailyReturn);
        return {
          x: new Date(date).getTime(),
          y: cumulativeReturn
        };
      });
    } else {
      // For other time ranges, we use additive cumulative returns
      let cumulativeReturn = 0;
      return filteredData.map(([date, dailyReturn]) => {
        cumulativeReturn += dailyReturn;
        return {
          x: new Date(date).getTime(),
          y: cumulativeReturn * 100  // Convert to percentage
        };
      });
    }
  }, [strategyReturns, timeRange]);

  const chartOptions = {
    chart: {
      type: 'line',
      height: 350,
      zoom: { enabled: false }
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      logarithmic: timeRange === 'ALL',
      logBase: timeRange === 'ALL' ? 10 : undefined,
      title: { text: timeRange === 'ALL' ? 'Cumulative Return (Multiple)' : 'Cumulative Return (%)' },
      labels: {
        formatter: (value) => timeRange === 'ALL' ? `${value.toFixed(2)}x` : `${value.toFixed(2)}%`
      }
    },
    tooltip: {
      x: {
        format: 'dd MMM yyyy'  // This line adds the year to the tooltip
      },
      y: {
        formatter: (value) => timeRange === 'ALL' ? `${value.toFixed(2)}x` : `${value.toFixed(2)}%`
      }
    },
    stroke: { curve: 'smooth', width: 2 },
    title: { text: 'Cumulative Returns', align: 'left' }
  };

  return (
    <div className="chart-container">
      <ReactApexChart 
        options={chartOptions} 
        series={[{ name: 'Cumulative Return', data: chartData }]} 
        type="line" 
        height={350} 
      />
    </div>
  );
};

function getStartDate(now, timeRange) {
  switch (timeRange) {
    case 'YTD': return new Date(now.getFullYear(), 0, 1);
    case 'MTD': return new Date(now.getFullYear(), now.getMonth(), 1);
    case '1M': return new Date(now.setMonth(now.getMonth() - 1));
    case '3M': return new Date(now.setMonth(now.getMonth() - 3));
    case '6M': return new Date(now.setMonth(now.getMonth() - 6));
    case '1Y': return new Date(now.setFullYear(now.getFullYear() - 1));
    case '5Y': return new Date(now.setFullYear(now.getFullYear() - 5));
    case 'ALL': return new Date(1989, 2, 1); // Start from January 1, 1993
    default: return new Date(0);
  }
}
