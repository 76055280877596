import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import ChatPage from './pages/Chat';
import Dashboard from './pages/Dashboard';
import Strategies from './pages/Strategies';
import Performance from './pages/Performance';
import Settings from './pages/Settings';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import ForgotPassword from './components/Auth/ForgotPassword';
import ResetPassword from './components/Auth/ResetPassword';
import ApiDocumentation from './components/ApiDocumentation';
import Payment from './components/Payment/Payment';
import PaymentSuccess from './components/Payment/PaymentSuccess';
import PaymentCancel from './components/Payment/PaymentCancel';
import Execution from './pages/Execution';

const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));

  return (
    <Routes>
      <Route path="/login" element={token ? <Dashboard setToken={setToken} /> : <Login setToken={setToken} />} />
      <Route path="/register" element={token ? <Dashboard setToken={setToken} /> : <Register />} />
      <Route path="/forgot-password" element={token ? <Dashboard setToken={setToken} /> : <ForgotPassword />} />
      <Route path="/reset-password" element={token ? <Dashboard setToken={setToken} /> : <ResetPassword />} />
      <Route path="/dashboard" element={token ? <Dashboard setToken={setToken} /> : <Login setToken={setToken} />} />
      <Route path="/" element={<Dashboard setToken={setToken} />} />
      <Route path="/strategies" element={token ? <Strategies setToken={setToken} /> : <Login setToken={setToken} />} />
      <Route path="/performance" element={token ? <Performance setToken={setToken} /> : <Login setToken={setToken} />} />
      <Route path="/settings" element={token ? <Settings setToken={setToken} /> : <Login setToken={setToken} />} />
      <Route path="/api-docs" element={token ? <ApiDocumentation setToken={setToken} /> : <Login setToken={setToken} />} />
      <Route path="/execution" element={token ? <Execution setToken={setToken} /> : <Login setToken={setToken} />} />
      
      {/* Payment Routes */}
      <Route 
        path="/payment" 
        element={token ? <Payment setToken={setToken} /> : <Login setToken={setToken} />} 
      />
      <Route 
        path="/payment-success" 
        element={token ? <PaymentSuccess setToken={setToken} /> : <Login setToken={setToken} />} 
      />
      <Route 
        path="/payment-cancel" 
        element={token ? <PaymentCancel setToken={setToken} /> : <Login setToken={setToken} />} 
      />
      <Route 
        path="/chat" 
        element={
          <PrivateRoute>
            <ChatPage setToken={setToken} />
          </PrivateRoute>
        } 
      />
      <Route 
        path="/payment/cancel" 
        element={
          <PrivateRoute>
            <PaymentCancel />
          </PrivateRoute>
        } 
      />
    </Routes>
  );
};

export default App;