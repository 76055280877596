import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

const DrawdownChart = ({ strategyDrawdown, spyDrawdown, startDate, endDate }) => {
  // Memoize the data processing
  const processedData = useMemo(() => {
    if (!strategyDrawdown || !spyDrawdown) return null;

    const start = new Date(startDate);
    const end = new Date(endDate);

    // Process all data in one pass
    const processed = {
      strategy: [],
      spy: [],
      minValue: 0
    };

    // Pre-calculate dates once
    const strategyDates = Object.keys(strategyDrawdown);
    const spyDates = Object.keys(spyDrawdown);

    // Process strategy drawdown
    strategyDates.forEach(date => {
      const currentDate = new Date(date);
      if (currentDate >= start && currentDate <= end) {
        const value = strategyDrawdown[date];
        processed.strategy.push({
          x: currentDate.getTime(),
          y: value
        });
        processed.minValue = Math.min(processed.minValue, value);
      }
    });

    // Process spy drawdown
    spyDates.forEach(date => {
      const currentDate = new Date(date);
      if (currentDate >= start && currentDate <= end) {
        const value = spyDrawdown[date];
        processed.spy.push({
          x: currentDate.getTime(),
          y: value
        });
        processed.minValue = Math.min(processed.minValue, value);
      }
    });

    return processed;
  }, [strategyDrawdown, spyDrawdown, startDate, endDate]);

  // Memoize the chart options
  const options = useMemo(() => ({
    chart: {
      type: 'area',
      height: 350,
      toolbar: {
        show: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    stroke: {
      curve: 'smooth',
      width: [2, 2],
      colors: ['#e74c3c', '#3498db']
    },
    dataLabels: {
      enabled: false
    },
    title: {
      text: 'Portfolio Drawdown',
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 500
      }
    },
    xaxis: {
      type: 'datetime',
      labels: {
        show: true,
        datetimeUTC: false,
        format: 'MMM yyyy',
        style: {
          colors: '#666',
          fontSize: '12px'
        }
      },
      axisBorder: {
        show: true,
        color: '#e0e0e0'
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        show: true,
        formatter: (value) => `${(value * 100).toFixed(0)}%`,
        style: {
          colors: '#666',
          fontSize: '12px'
        }
      },
      min: processedData?.minValue * 1.1 || 0,
      max: 0,
      tickAmount: 5
    },
    grid: {
      show: true,
      borderColor: '#f1f1f1',
      strokeDashArray: 4,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
      padding: {
        right: 20
      }
    },
    tooltip: {
      enabled: true,
      shared: true,
      followCursor: true,
      theme: 'dark',
      x: {
        show: true,
        format: 'dd MMM yyyy'
      },
      y: {
        formatter: (value) => `${(value * 100).toFixed(2)}%`
      }
    },
    colors: ['#e74c3c', '#3498db'],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100]
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      offsetY: -10,
      labels: {
        colors: '#666'
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        radius: 12,
        offsetX: -5
      },
      itemMargin: {
        horizontal: 10
      }
    }
  }), [processedData]);

  // Memoize the series data
  const series = useMemo(() => [
    {
      name: 'Strategy Drawdown',
      data: processedData?.strategy || []
    },
    {
      name: 'S&P 500 Drawdown',
      data: processedData?.spy || []
    }
  ], [processedData]);

  if (!processedData) return null;

  return (
    <div className="chart-container">
      <ReactApexChart 
        options={options} 
        series={series} 
        type="area" 
        height={350} 
      />
    </div>
  );
};

export default React.memo(DrawdownChart);  // Memoize the entire component
