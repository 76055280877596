import { authInstance } from '../utils/axiosConfig';

export const downloadExecutionScript = async () => {
  try {
    const response = await authInstance.get('/AYbQ-SRnbw639hWMNZsBpumFEodXX8r8CUPWntIbMls/download_execution_script/', {
      responseType: 'blob'  // Important for file downloads
    });
    
    // Create blob and trigger download
    const blob = new Blob([response.data], { type: 'text/x-python' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'ib_execution_script.py';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    
    return true;
  } catch (error) {
    console.error('Error downloading execution script:', error.response?.data || error.message);
    throw error;
  }
};
