/**
 * API Documentation for Portfolio Management
 * 
 * This documentation covers the endpoints related to portfolio positions and returns.
 * Base URL: /api/portfolio/
 */

const apiDocs = {
  getPortfolioPositions: {
    endpoint: '/api/AYbQ-SRnbw639hWMNZsBpumFEodXX8r8CUPWntIbMls/portfolio_positions/',
    method: 'GET',
    description: 'Retrieves the current positions in the portfolio.',
    parameters: 'None',
    headers: {
      'Authorization': 'Token <token>'
    },
    responseFormat: `
    [
      {'ticker': 'AAPL', 
      'allocation': 7.04}, // in percent 
      {'ticker': 'AZN', 
      'allocation': 0.79}
      // ... other positions
    ]
    `,
    notes: 'Requires authentication. Returns an array of position objects.',
    pythonExample: `
import requests

url = "https://thesimpleportfol.io/api/AYbQ-SRnbw639hWMNZsBpumFEodXX8r8CUPWntIbMls/portfolio_positions/"
headers = {
    "Authorization": "Token your_access_token_here"
}

response = requests.get(url, headers=headers)

if response.status_code == 200:
    positions = response.json()
    for position in positions:
        print(f"Symbol: {position['ticker']}, Quantity: {position['allocation']}")
else:
    print(f"Error: {response.status_code}, {response.text}")
    `,
    bashExample: `
curl -X GET "https://thesimpleportfol.io/api/AYbQ-SRnbw639hWMNZsBpumFEodXX8r8CUPWntIbMls/portfolio_positions/" \\
     -H "Authorization: Token your_access_token_here"
    `
  },

  getPortfolioReturns: {
    endpoint: '/api/AYbQ-SRnbw639hWMNZsBpumFEodXX8r8CUPWntIbMls/portfolio_returns/',
    method: 'GET',
    description: 'Retrieves the historical returns of the portfolio.',
    parameters: 'None',
    headers: {
      'Authorization': 'Token <token>'
    },
    responseFormat: `
    {
      "returns":
        {
          "2023-05-01":0.00182,
          "2023-05-02":0.00123,
          ...
        },
    }
    `,
    notes: 'Requires authentication. If start_date and end_date are not provided, returns data for the last 30 days.',
    pythonExample: `
import requests

url = "https://thesimpleportfol.io/api/AYbQ-SRnbw639hWMNZsBpumFEodXX8r8CUPWntIbMls/portfolio_returns/"
headers = {
    "Authorization": "Token your_access_token_here"
}

response = requests.get(url, headers=headers)

if response.status_code == 200:
    returns_data = response.json()
    print(f"Total Return: {returns_data['total_return']}%")
    for daily_return in returns_data['daily_returns']:
        print(f"Date: {daily_return['date']}, Return: {daily_return['return']}%")
else:
    print(f"Error: {response.status_code}, {response.text}")
    `,
    bashExample: `
curl -X GET "https://thesimpleportfol.io/api/AYbQ-SRnbw639hWMNZsBpumFEodXX8r8CUPWntIbMls/portfolio_returns/" \\
     -H "Authorization: Token your_access_token_here"
    `
  }
};

export default apiDocs;
