import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

export const CumulativeReturnsChart = ({ strategyReturns, spyReturns, startDate, endDate }) => {
  const chartData = useMemo(() => {
    if (!strategyReturns || !spyReturns || typeof strategyReturns !== 'object' || typeof spyReturns !== 'object') {
      return [];
    }

    const returnsData = strategyReturns.returns || strategyReturns;
    const start = new Date(startDate);
    const end = new Date(endDate);

    let filteredStrategyData = Object.entries(returnsData)
      .filter(([date]) => {
        const currentDate = new Date(date);
        return currentDate >= start && currentDate <= end;
      })
      .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB));

    let filteredSpyData = Object.entries(spyReturns)
      .filter(([date]) => {
        const currentDate = new Date(date);
        return currentDate >= start && currentDate <= end;
      })
      .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB));

    if (filteredStrategyData.length === 0 || filteredSpyData.length === 0) return [];

    let cumulativeStrategyReturn = 1;
    let cumulativeSpyReturn = 1;

    const formattedData = filteredStrategyData.map(([date, strategyReturn], index) => {
      cumulativeStrategyReturn *= (1 + strategyReturn);
      const spyReturn = filteredSpyData[index] ? filteredSpyData[index][1] : 0;
      cumulativeSpyReturn *= (1 + spyReturn);
      
      return {
        x: new Date(date).getTime(),
        y: [(cumulativeStrategyReturn - 1) * 100, (cumulativeSpyReturn - 1) * 100]
      };
    });

    return formattedData;
  }, [strategyReturns, spyReturns, startDate, endDate]);

  const options = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false
      }
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    title: {
      text: 'Cumulative Returns',
      align: 'left'
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      title: {
        text: 'Cumulative Return (%)'
      },
      labels: {
        formatter: (value) => `${value.toFixed(2)}%`
      }
    },
    legend: {
      position: 'top'
    },
    tooltip: {
      shared: true,
      x: {
        format: 'dd MMM yyyy'
      },
      y: {
        formatter: (value) => `${value.toFixed(2)}%`
      }
    }
  };

  const series = [
    {
      name: 'Strategy',
      data: chartData.map(item => [item.x, item.y[0]])
    },
    {
      name: 'SP500',
      data: chartData.map(item => [item.x, item.y[1]])
    }
  ];

  if (chartData.length === 0) {
    return <div>No data available for the selected date range</div>;
  }

  return (
    <div className="chart-container">
      <ReactApexChart 
        options={options} 
        series={series} 
        type="line" 
        height={350} 
      />
    </div>
  );
};

export default CumulativeReturnsChart;