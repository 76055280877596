import React, { useMemo } from 'react';
import styled from 'styled-components';

const DrawdownTable = styled.div`
  background: white;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  
  h3 {
    margin-bottom: 1rem;
    color: #2c3e50;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    
    th, td {
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid #eee;
    }
    
    th {
      font-weight: 600;
      color: #666;
      background: #f8f9fa;
    }
    
    td {
      color: #2c3e50;
    }

    tr:last-child td {
      border-bottom: none;
    }

    .negative {
      color: #e74c3c;
    }

    .duration {
      color: #7f8c8d;
    }
  }
`;

const DrawdownAnalysis = ({ drawdownData }) => {
  const START_YEAR = 2000;
  const START_DATE = new Date(`${START_YEAR}-01-01`);

  const topDrawdowns = useMemo(() => {
    if (!drawdownData) return [];

    // Convert drawdown data to array and filter for dates from 2000
    const drawdowns = Object.entries(drawdownData)
      .map(([date, value]) => ({
        date: new Date(date),
        value
      }))
      .filter(item => item.date >= START_DATE);

    // Sort by date
    drawdowns.sort((a, b) => a.date - b.date);

    let drawdownPeriods = [];
    let currentDrawdown = {
      start: null,
      peak: 0,
      trough: 0,
      troughDate: null,
      recovery: null,
      duration: 0,
      drawdown: 0,
      recoveryDuration: 0  // Added recovery duration
    };

    // Find drawdown periods
    for (let i = 0; i < drawdowns.length; i++) {
      const current = drawdowns[i];
      
      if (current.value < 0) {
        // Start of new drawdown or continuation
        if (currentDrawdown.start === null) {
          currentDrawdown.start = current.date;
          currentDrawdown.peak = 0;
        }
        
        // Update maximum drawdown if current is lower
        if (current.value < currentDrawdown.trough) {
          currentDrawdown.trough = current.value;
          currentDrawdown.troughDate = current.date;
          currentDrawdown.drawdown = current.value;
        }
      } else if (currentDrawdown.start !== null) {
        // Potential recovery
        currentDrawdown.recovery = current.date;
        currentDrawdown.duration = Math.floor(
          (current.date - currentDrawdown.start) / (1000 * 60 * 60 * 24)
        );
        
        drawdownPeriods.push({ ...currentDrawdown });
        
        // Reset for next drawdown
        currentDrawdown = {
          start: null,
          peak: 0,
          trough: 0,
          troughDate: null,
          recovery: null,
          duration: 0,
          drawdown: 0,
          recoveryDuration: 0
        };
      }
    }

    // Sort by drawdown magnitude and get top 10
    return drawdownPeriods
      .sort((a, b) => a.drawdown - b.drawdown)
      .slice(0, 10);
  }, [drawdownData]);

  if (!drawdownData || topDrawdowns.length === 0) {
    return <div>No drawdown data available</div>;
  }

  return (
    <DrawdownTable>
      <h3>Top 10 Drawdowns Over Last 25 Years</h3>
      <table>
        <thead>
          <tr>
            <th>Start Date</th>
            <th>Trough Date</th>
            <th>Recovery Date</th>
            <th>Duration (Days)</th>
            <th>Drawdown</th>
          </tr>
        </thead>
        <tbody>
          {topDrawdowns.map((drawdown, index) => (
            <tr key={index}>
              <td>{drawdown.start.toLocaleDateString()}</td>
              <td>{drawdown.troughDate.toLocaleDateString()}</td>
              <td>{drawdown.recovery ? drawdown.recovery.toLocaleDateString() : 'Ongoing'}</td>
              <td className="duration">{drawdown.duration}</td>
              <td className="negative">
                {(drawdown.drawdown * 100).toFixed(2)}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </DrawdownTable>
  );
};

export default DrawdownAnalysis; 