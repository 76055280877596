import React from 'react';
import styled from 'styled-components';
import { Sidebar } from './Dashboard/Sidebar';
import apiDocs from '../docs/apiDocs';

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  padding: 2rem;
  overflow-y: auto;
  background-color: #f8f9fa;
`;

const DocContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
`;

const Title = styled.h1`
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 2.5rem;
`;

const EndpointSection = styled.div`
  margin-bottom: 3rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1.5rem;
  background-color: #ffffff;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  }
`;

const EndpointTitle = styled.h2`
  color: #2c3e50;
  margin-bottom: 1rem;
  border-bottom: 2px solid #3498db;
  padding-bottom: 0.5rem;
`;

const EndpointInfo = styled.p`
  margin-bottom: 0.5rem;
  font-size: 1rem;
`;

const SectionTitle = styled.h3`
  color: #34495e;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const Pre = styled.pre`
  background-color: #f7f9fb;
  padding: 1rem;
  border-radius: 4px;
  overflow-x: auto;
  border: 1px solid #e0e0e0;
  font-size: 0.9rem;
`;

const Badge = styled.span`
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: bold;
  margin-right: 0.5rem;
  background-color: ${props => {
    switch (props.method) {
      case 'GET': return '#61affe';
      case 'POST': return '#49cc90';
      case 'PUT': return '#fca130';
      case 'DELETE': return '#f93e3e';
      default: return '#6c757d';
    }
  }};
  color: white;
`;

const ExampleTitle = styled.h4`
  color: #2c3e50;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const ApiDocumentation = ({ setToken }) => {
  return (
    <PageContainer>
      <Sidebar setToken={setToken} />
      <ContentContainer>
        <DocContainer>
          <Title>API Documentation</Title>
          {Object.entries(apiDocs).map(([key, doc]) => (
            <EndpointSection key={key}>
              <EndpointTitle>{key}</EndpointTitle>
              <EndpointInfo>
                <Badge method={doc.method}>{doc.method}</Badge>
                {doc.endpoint}
              </EndpointInfo>
              <EndpointInfo><strong>Description:</strong> {doc.description}</EndpointInfo>
              <SectionTitle>Parameters:</SectionTitle>
              {typeof doc.parameters === 'string' ? (
                <EndpointInfo>{doc.parameters}</EndpointInfo>
              ) : (
                <List>
                  {Object.entries(doc.parameters).map(([param, desc]) => (
                    <ListItem key={param}><strong>{param}:</strong> {desc}</ListItem>
                  ))}
                </List>
              )}
              <SectionTitle>Headers:</SectionTitle>
              <List>
                {Object.entries(doc.headers).map(([header, value]) => (
                  <ListItem key={header}><strong>{header}:</strong> {value}</ListItem>
                ))}
              </List>
              <SectionTitle>Response Format:</SectionTitle>
              <Pre>{doc.responseFormat}</Pre>
              <EndpointInfo><strong>Notes:</strong> {doc.notes}</EndpointInfo>
              <ExampleTitle>Python Example:</ExampleTitle>
              <Pre>{doc.pythonExample}</Pre>
              <ExampleTitle>Bash Example:</ExampleTitle>
              <Pre>{doc.bashExample}</Pre>
            </EndpointSection>
          ))}
        </DocContainer>
      </ContentContainer>
    </PageContainer>
  );
};

export default ApiDocumentation;
