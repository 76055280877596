import React, { useState, useCallback, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { 
  FaComments, 
  FaChartLine, 
  FaChartBar,
  FaCog, 
  FaSignOutAlt,
  FaCode, 
  FaBook
} from 'react-icons/fa';
import '../../styles/Sidebar.css';
import { loginInstance } from '../../utils/axiosConfig';

export const Sidebar = ({username, setToken, activeMenuItem, setActiveMenuItem }) => {
  const navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState('');

  useEffect(() => {
    // Check subscription plan
    const checkSubscription = async () => {
      try {
        const response = await loginInstance.get('/payments/subscription-details/');
        setSubscriptionPlan(response.data.planName?.toLowerCase());
      } catch (error) {
        console.error('Failed to check subscription status:', error);
      }
    };
    checkSubscription();
  }, []);

  // Define menu items based on subscription
  const getMenuItems = () => {
    const baseItems = [
      { id: 'dashboard', label: 'Dashboard', icon: FaChartLine },
      { id: 'performance', label: 'Performance', icon: FaChartBar },
      { id: 'api-docs', label: 'API', icon: FaBook },
      { id: 'settings', label: 'Settings', icon: FaCog },
    ];

    // Add Execution for advanced users only
    if (subscriptionPlan === 'advanced') {
      baseItems.splice(2, 0, { 
        id: 'execution', 
        label: 'Execution', 
        icon: FaCode 
      });
    }

    return baseItems;
  };

  const handleLogout = useCallback(async () => {
    if (isLoggingOut) return;

    setIsLoggingOut(true);
    try {
      await loginInstance.post('/auth/logout/');
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      setToken(null);
      window.location.href = '/login';
    }
  }, [setToken, isLoggingOut]);

  const handleMenuClick = (menuId) => {
    if (menuId === 'logout') {
      handleLogout();
    } else {
      setActiveMenuItem(menuId);
      navigate(`/${menuId}`);
    }
  };

  return (
    <nav className="sidebar">
      <div className="welcome-message">
        <p>Welcome {username || 'User'}</p>
      </div>
      <ul>
        {getMenuItems().map(item => (
          <li key={item.id}>
            <NavLink 
              to={`/${item.id}`} 
              className={activeMenuItem === item.id ? 'active' : ''}
              onClick={() => handleMenuClick(item.id)}
            >
              <item.icon /> {item.label}
            </NavLink>
          </li>
        ))}
      </ul>
      <div className="sidebar-footer">
        <button 
          onClick={handleLogout} 
          className="logout-button" 
          disabled={isLoggingOut}
        >
          <FiLogOut /> {isLoggingOut ? 'Logging out...' : 'Logout'}
        </button>
      </div>
    </nav>
  );
};
