import React, { useState } from 'react';
import { Sidebar } from '../components/Dashboard/Sidebar';
import styled from 'styled-components';
import { FaDownload, FaInfoCircle, FaCode, FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';
import { downloadExecutionScript } from '../api/executionService';

const ContentContainer = styled.div`
  flex: 1;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const Section = styled.div`
  background: white;
  border-radius: 10px;
  padding: 2.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h2`
  color: #2c3e50;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  font-size: 1.8rem;
`;

const Instructions = styled.div`
  margin-bottom: 2rem;
  
  h3 {
    color: #2c3e50;
    margin: 1.5rem 0 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 1rem;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li {
    color: #666;
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;

    &:before {
      content: "•";
      color: #3498db;
      position: absolute;
      left: 0;
    }
  }
`;

const DownloadButton = styled.button`
  background: #3498db;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
  
  &:hover {
    background: #2980b9;
  }

  &:disabled {
    background: #bdc3c7;
    cursor: not-allowed;
  }
`;

const CodeBlock = styled.pre`
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 5px;
  overflow-x: auto;
  margin: 1rem 0;
  font-family: monospace;
`;

const StatusMessage = styled.div`
  margin-top: 1rem;
  text-align: center;
  color: ${props => props.error ? '#e74c3c' : '#27ae60'};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const Execution = ({ setToken }) => {
  const [activeMenuItem, setActiveMenuItem] = useState('execution');
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState(null);

  const handleDownload = async () => {
    setIsDownloading(true);
    setDownloadStatus(null);
    
    try {
      await downloadExecutionScript();
      setDownloadStatus({ 
        success: true, 
        message: 'Script downloaded successfully!' 
      });
    } catch (error) {
      console.error('Error:', error);
      setDownloadStatus({ 
        success: false, 
        message: 'Failed to download script. Please try again.' 
      });
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="dashboard">
      <Sidebar
        username={username}
        setToken={setToken}
        activeMenuItem={activeMenuItem}
        setActiveMenuItem={setActiveMenuItem}
      />
      <ContentContainer>
        <div style={{ width: '100%', maxWidth: '800px' }}>
          <Section>
            <Title>
              <FaCode /> Strategy Execution Guide
            </Title>
            
            <Instructions>
              <h3><FaInfoCircle /> Prerequisites</h3>
              <ul>
                <li>Interactive Brokers account with API access enabled</li>
                <li>Python 3.7 or higher installed on your computer</li>
                <li>Trader Workstation (TWS) installed and running</li>
              </ul>

              <h3><FaInfoCircle /> Setup Instructions</h3>
              <ol>
                <li>Enable API connections in your Interactive Brokers account</li>
                <li>Configure TWS to accept API connections</li>
                <li>Install required Python packages:</li>
              </ol>

              <CodeBlock>
                pip install ibapi pandas requests
              </CodeBlock>

              <h3><FaInfoCircle /> Configuration</h3>
              <p>
                Before running the script, you'll need to:
              </p>
              <ul>
                <li>Set your TWS port number (default: 7496/7497)</li>
                <li>Configure your API endpoint and authentication token.</li>
                <li>Ensure your account has sufficient buying power</li>
              </ul>

              <h3><FaInfoCircle /> Running the Script</h3>
              <p>
                The script will:
              </p>
              <ul>
                <li>Connect to Interactive Brokers via the API</li>
                <li>Fetch the latest portfolio allocation from our API</li>
                <li>Execute trades to match the target allocation</li>
                <li>Provide real-time feedback on execution progress</li>
              </ul>
            </Instructions>

            <DownloadButton 
              onClick={handleDownload}
              disabled={isDownloading}
            >
              {isDownloading ? (
                'Downloading...'
              ) : (
                <>
                  <FaDownload /> Download Execution Script
                </>
              )}
            </DownloadButton>

            {downloadStatus && (
              <StatusMessage error={!downloadStatus.success}>
                {downloadStatus.success ? (
                  <>
                    <FaCheckCircle />
                    {downloadStatus.message}
                  </>
                ) : (
                  <>
                    <FaExclamationTriangle />
                    {downloadStatus.message}
                  </>
                )}
              </StatusMessage>
            )}
          </Section>
        </div>
      </ContentContainer>
    </div>
  );
};

export default Execution;
