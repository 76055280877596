import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { loginInstance } from '../../utils/axiosConfig';
import styled from 'styled-components';
import loginBackgroundImage from '../../images/password.jpg';

const ForgotPasswordContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
`;

const ImageSection = styled.div`
  background-image: url(${loginBackgroundImage});
  background-size: cover;
  background-position: center;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #f8f9fa;
`;

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 400px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: 1rem;
`;

const ForgotPassword = () => {
  const [username, setUsername] = useState('');
  const [token, setToken] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    try {
      const response = await loginInstance.post('/auth/forgot-password/', { username });
      setToken(response.data.token);
      setMessage('Password reset token generated. Please use this token to reset your password.');
    } catch (error) {
      console.error('Password reset request failed:', error);
      setError(error.response?.data?.detail || 'Something went wrong. Please try again.');
    }
  };

  return (
    <ForgotPasswordContainer>
      <ImageSection />
      <FormSection>
        <h2 className="text-center mb-4">Forgot Password</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        {message && <Alert variant="success">{message}</Alert>}
        <StyledForm onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Form.Group>
          <StyledButton variant="primary" type="submit">
            Get Reset Token
          </StyledButton>
        </StyledForm>
        {token && (
          <Alert variant="info" className="mt-3">
            Your reset token: {token}
            <br />
            <Link to="/reset-password">Reset your password</Link>
          </Alert>
        )}
        <div className="text-center mt-3">
          Remember your password? <Link to="/login">Login</Link>
        </div>
      </FormSection>
    </ForgotPasswordContainer>
  );
};

export default ForgotPassword;
