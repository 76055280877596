import { useState, useEffect, useCallback } from 'react';
import { getStrategyReturns } from '../api/strategyService';

export const useStrategyReturns = () => {
  const [strategyReturns, setStrategyReturns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAllData = useCallback(async () => {
    try {
      const data = await getStrategyReturns();
      console.log('API response:', data);
      
      // Filter returns after 1995
      const filteredReturns = Object.entries(data.returns)
        .filter(([date]) => new Date(date) >= new Date('1980-06-01'))
        .reduce((acc, [date, value]) => {
          acc[date] = value;
          return acc;
        }, {});

      setStrategyReturns({ ...data, returns: filteredReturns });
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  return { strategyReturns, isLoading };
};
